/* eslint-disable @typescript-eslint/no-explicit-any */
import { RECAPTCHA_ENABLED } from 'config';
import { ErrorsType } from 'interfaces';
import { useClient } from 'modules/settings/providers/ClientProvider';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormProvider, useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form';
import Button from 'stories/Button';
import RecaptchaDisclaimer from 'stories/RecaptchaDislaimer';
import { Body, Display } from 'stories/Typography';
import { useTheme } from 'styled-components';
import Notification from 'ui/Notification';
import { MethodsType } from 'utils/helpers/renderFormInputFields/types';

import { ForgotPassword } from './ForgotPassword';
import { ForgotPasswordCheckEmail } from './ForgotPasswordCheckEmail';
import { MultiFactorAuth } from './MultiFactorAuth';
import { OnboardingConfigureContactMFA } from './OnboardingConfigureContactMFA';
import { OnboardingMultiFactorAuth } from './OnboardingMultiFactorAuth';
import OnboardingPassword from './OnboardingPassword';
import ResetPassword from './ResetPassword';
import { ResetPasswordMultiFactorAuth } from './ResetPasswordMultiFactorAuth';
import { ResetSendMfa } from './ResetSendMfa';
import { SendMfa } from './SendMfa';
import { SignIn } from './SignIn';
import { ActionsWrapper, FieldsWrapper, SubWrapper, Wrapper } from './styles';
import { PageNameType } from './types';

interface RenderPageFormParams {
  pageName: PageNameType;
  methods: MethodsType;
}

interface AuthPagesProps {
  pageName: PageNameType;
  mfaMethod?: string;
  loading?: boolean;
  errors?: ErrorsType;
  resetErrors?: (code: number) => void;
  onSubmit?: SubmitHandler<Record<string, string> | Record<string, any>>;
}

const renderPageForm = ({ pageName, methods }: RenderPageFormParams) => {
  switch (pageName) {
    case 'signin': {
      return <SignIn methods={methods} />;
    }
    case 'forgot-password': {
      return <ForgotPassword methods={methods} />;
    }
    case 'forgot-password-check-email': {
      return <ForgotPasswordCheckEmail />;
    }
    case '2-fa': {
      return <MultiFactorAuth methods={methods} />;
    }
    case 'onboarding': {
      return <OnboardingConfigureContactMFA methods={methods} />;
    }
    case '2-fa-onboarding': {
      return <OnboardingMultiFactorAuth methods={methods} />;
    }
    case 'onboarding-password': {
      return <OnboardingPassword methods={methods} />;
    }
    case 'send-2-fa': {
      return <SendMfa methods={methods} />;
    }
    case 'reset-password': {
      return <ResetPassword methods={methods} />;
    }
    case '2-fa-reset-password': {
      return <ResetPasswordMultiFactorAuth methods={methods} />;
    }
    case 'send-2-fa-reset-password': {
      return <ResetSendMfa methods={methods} />;
    }
    default: {
      return null;
    }
  }
};

const getTextsByPageName = (
  pageName: PageNameType,
  mfaMethod: string | undefined
) => {
  const textsMapper = {
    signin: {
      title: 'Sign In',
      subtitle: 'First, enter the email address and password for your account.',
      submitButton: 'Next',
    },
    'forgot-password': {
      title: 'Sign In Help',
      subtitle: 'Please enter the email address we have on file for you.',
      submitButton: 'Next',
    },
    'forgot-password-check-email': {
      title: 'Sign In Help',
      subtitle: '',
      submitButton: '',
    },
    '2-fa': {
      title: 'Multi-Factor Authentication',
      subtitle: `Please enter the 8 digit code sent to ${
        mfaMethod === 'phone'
          ? 'your mobile number.'
          : mfaMethod === 'email'
          ? 'your email address.'
          : 'the device we have on file for you.'
      }`,
      submitButton: 'Submit',
    },
    onboarding: {
      title: 'Create Profile',
      subtitle: `For your security, we must send a multi-factor authentication code${
        mfaMethod === 'both'
          ? ' to either your mobile number or your email address.'
          : mfaMethod === 'phone'
          ? ' to your mobile number.'
          : mfaMethod === 'email'
          ? ' to your email address.'
          : '.'
      }`,
      submitButton: 'Next',
    },
    '2-fa-onboarding': {
      title: 'Create Profile',
      subtitle: `Please enter the 8 digit code sent to the ${
        mfaMethod === 'phone'
          ? 'mobile number'
          : mfaMethod === 'email'
          ? 'email'
          : 'device'
      } you specified.`,
      submitButton: 'Next',
    },
    'onboarding-password': {
      title: 'Create Profile',
      subtitle: 'Next, create a password for your account.',
      submitButton: 'Next',
    },
    'send-2-fa': {
      title: 'Multi-Factor Authentication',
      subtitle: `Select where you'd like to receive your multi-factor authentication code.`,
      submitButton: 'Next',
    },
    'reset-password': {
      title: 'Sign In Help',
      subtitle: `Please enter a new password.`,
      submitButton: 'Confirm New Password',
    },
    '2-fa-reset-password': {
      title: 'Sign In Help',
      subtitle: `Please enter the 8 digit code sent to ${
        mfaMethod === 'phone'
          ? 'your mobile number.'
          : mfaMethod === 'email'
          ? 'your email address.'
          : 'the device we have on file for you.'
      }`,
      submitButton: 'Next',
    },
    'send-2-fa-reset-password': {
      title: 'Sign In Help',
      subtitle: `Select where you'd like to receive your multi-factor authentication code.`,
      submitButton: 'Next',
    },
  };

  return textsMapper[pageName] || { title: 'Sign In' };
};

export default function AuthPages({
  pageName,
  mfaMethod,
  loading,
  errors,
  resetErrors,
  onSubmit,
}: AuthPagesProps) {
  const { client } = useClient();
  const theme = useTheme();
  const router = useRouter();
  const [disableButton, setDisableButton] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const texts = getTextsByPageName(pageName, mfaMethod);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });

  const handleSubmit = async (event: React.FormEvent) => {
    setDisableButton(true);
    event.preventDefault();
    const values = methods.getValues();
    if (RECAPTCHA_ENABLED && executeRecaptcha) {
      const token = await executeRecaptcha();
      values.reCaptchaCode = token;
    }
    onSubmit?.(values);
  };

  const handleChange = async () => {
    setDisableButton(false);
  };

  useEffect(() => {
    if (loading) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [loading]);

  const isCreateProfile = pageName === 'signin' && client === 'relevant';

  const isReCaptcha = RECAPTCHA_ENABLED;

  const submitButtonState = loading
    ? 'loading'
    : disableButton || !methods.formState.isValid
    ? 'inactive'
    : 'default';

  return (
    <Wrapper>
      <SubWrapper>
        <>
          {errors?.map((val, index) => (
            <Notification
              key={`notif-${index}`}
              msg={val.message}
              state="danger"
              onClick={() => val.code && resetErrors?.(val.code)}
            />
          ))}
        </>

        <Display
          size="large"
          color={theme.colors.grey[0]}
          spacing={{ mb: theme.spacing[4] }}
        >
          {texts.title}
        </Display>

        <Body
          size="medium"
          color={theme.colors.grey[0]}
          spacing={{ mb: theme.spacing[4] }}
        >
          {texts.subtitle}
        </Body>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit} onChange={handleChange}>
            <FieldsWrapper>
              {renderPageForm({ pageName, methods })}
            </FieldsWrapper>
            <ActionsWrapper>
              {isCreateProfile && (
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => router.push('/auth/signup')}
                >
                  Create a profile
                </Button>
              )}

              {texts.submitButton && (
                <Button
                  data-testid="submit-button"
                  size="large"
                  type="submit"
                  state={submitButtonState}
                >
                  {texts.submitButton}
                </Button>
              )}

              {isReCaptcha && <RecaptchaDisclaimer />}
            </ActionsWrapper>
          </form>
        </FormProvider>
      </SubWrapper>
    </Wrapper>
  );
}
